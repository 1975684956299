import React from "react";

import Root from "../components/Root";
import SEO from "../components/seo";
import AboutPage from "../components/About/AboutPage";

const About = () => {
  return (
    <Root>
      <SEO 
        title={`About WhereToMine`} 
        description={`About us at WhereToMine, our core values, mission and vision for the future.`}  />
      <AboutPage />
    </Root>
  );
};

export default About;
