import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  texts: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "80px auto",
    },
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    marginTop: "80px",
    fontWeight: "700",
    textTransform: "initial",
    padding: "12px 28px",
    borderRadius: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const AboutPage = props => {
  const classes = useStyles(props);

  return (
    <div>
      <Container maxWidth="lg">
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            What is WhereToMine?
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            WhereToMine is a web and mobile cryptocurrency mining pool explorer. View the best mining pools of over 700 cryptocurrencies
            with additional information such as payout scheme, fees, minimum and maximum payout, server locations, connection information
            and more. We are a small dedicated team of crypto miners and enthusiats. Give us a shout out on{" "}
            <a className={classes.link} href="http://twitter.com" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
            .
          </Typography>
        </div>
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Mission & Goals
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            Our mission is simple, promote decentralisation of mining. One of the first steps is to gather all the pools currently operating
            and create a standard for what it means to be a high quality pool. So far we have listed over 700 cryptocurrencies and over 6500
            mining pools, with more being added regularly.
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              endIcon={<ArrowForwardIosIcon fontSize="small" />}
            >
              Start Browsing Pools
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default AboutPage;
